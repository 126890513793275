import { type ComponentProps, splitProps } from 'solid-js';

import { type VariantProps, cva } from 'class-variance-authority';
import { cn } from '~/ui/methods/classNames';

export const linkVariants = cva('link', {
  variants: {
    underline: {
      never: 'link-no-underline',
      hover: 'link-hover',
      always: '',
    },
    color: {
      default: 'link-default',
      neutral: 'link-neutral',
      primary: 'link-primary',
      secondary: 'link-secondary',
      accent: 'link-accent',
      info: 'link-info',
      success: 'link-success',
      warning: 'link-warning',
      error: 'link-error',
    },
    disabled: {
      true: 'link-disabled',
    },
  },
  defaultVariants: {
    underline: 'hover',
    color: 'default',
  },
});

export interface LinkProps
  extends Omit<ComponentProps<'a'>, 'color'>,
    VariantProps<typeof linkVariants> {}

export const Link = (props: LinkProps) => {
  const [styleProps, otherProps] = splitProps(props, ['underline', 'color']);
  return (
    <a {...otherProps} class={cn(linkVariants(styleProps), props.class)} />
  );
};
