import Button from '~/ui/components/actions/Button';
import { FontAwesomeIcon } from '~/ui/components/icons/FontAwesomeIcon';
import { faLink } from '@fortawesome/pro-solid-svg-icons/faLink';
import { faLinkSlash } from '@fortawesome/pro-solid-svg-icons/faLinkSlash';
import { playerStore } from '~/players/stores/playerStore';
import { Tooltip } from '~/ui/components/feedback/Tooltip';

export const SyncPlayersButton = () => {
  const handleClick = () => {
    playerStore.synchronized = !playerStore.synchronized;
  };

  return (
    <Tooltip
      tip={
        playerStore.synchronized
          ? 'Detach player from project page'
          : 'Attach player to project page'
      }
    >
      <Button
        round
        color='secondary'
        variant={'link'}
        size={'sm'}
        onClick={handleClick}
      >
        <FontAwesomeIcon
          class={'h-4'}
          icon={playerStore.synchronized ? faLink : faLinkSlash}
        />
      </Button>
    </Tooltip>
  );
};
